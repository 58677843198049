<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :disabled="true"
            :afterIcon="[
              { name: 'search', click: true, callbackName: 'searchSop', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeSop', color: 'red' }
            ]"
            label="관찰작업"
            name="sopName"
            v-model="searchParam.sopName"
            @searchSop="openSop"
            @removeSop="removeSop">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관찰기간 -->
          <c-datepicker
            :range="true"
            label="관찰기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="행동관찰 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cto',
  data() {
    return {
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        mdmSopId: '',
        sopName: '',
        period: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'ctoCompleteFlagName',
            field: 'ctoCompleteFlagName',
            label: '진행상태',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'ctoDate',
            field: 'ctoDate',
            label: '관찰일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '관찰작업',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'link',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '작업공정',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'observeUserName',
            field: 'observeUserName',
            label: '관찰자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'workArea',
            field: 'workArea',
            label: '작업구역',
            align: 'left',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      listUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.cto.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick();
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '행동관찰 상세'; // 행동관찰 상세
      this.popupOptions.param = {
        sopCtoId: row ? row.sopCtoId : '',
      };
      this.popupOptions.target = () => import(`${'./ctoDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    openSop() {
      this.popupOptions.title = 'SOP 검색'; // SOP 검색
      this.popupOptions.param = {
        type: 'single',
        searchType: '1', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.mdmSopId = data[0].mdmSopId;
        this.searchParam.sopName = data[0].sopName;
      }
    },
    removeSop() {
      this.searchParam.mdmSopId = '';
      this.searchParam.sopName = '';
    },
  }
};
</script>
